import React from "react";

function NotFoundError() {
  return (
    <>
      <div class="container">
        <section class="section">
          <h1>404 - Page does not exist</h1>
        </section>
      </div>
    </>
  );
}

export default NotFoundError;
