import React from "react";

function NotImplementedError() {
  return (
    <>
      <div class="container">
        <section class="section">
          <h1>501 - Page not implemented by programmer</h1>
        </section>
      </div>
    </>
  );
}

export default NotImplementedError;
